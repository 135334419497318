<template>
  <div class="auth-wrapper auth-v2">
    <div class="auth-inner">
      <v-row class="auth-row ma-0">
        <v-col
          lg="8"
          class="d-none d-lg-block position-relative overflow-hidden pa-0"
        >
          <div class="auth-illustrator-wrapper">
            <!-- triangle bg -->
            <img
              height="362"
              class="auth-mask-bg"
              :src="require(`@/assets/images/misc/mask-v2-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
            />
          </div>
        </v-col>

        <v-col
          lg="4"
          class="d-flex align-center auth-bg pb-0"
        >
          <v-row>
            <v-col
              cols="12"
              sm="8"
              md="6"
              lg="12"
              class="mx-auto"
            >
              <v-card
                flat
              >
                <v-card-text>
                  <!--/ brand logo -->
                  <div class="d-flex justify-center">
                    <v-img
                      :src="require(`@/assets/images/logos/Logo-EN-${$vuetify.theme.dark ? 'white' : 'black'}.svg`)"
                      max-width="80%"
                      alt="logo"
                      contain
                    ></v-img>
                  </div>

                  <!--/ brand logo -->
                </v-card-text>
                <!-- <v-card-text>
                  <v-alert
                    text
                    color="primary"
                  >
                    <small class="d-block mb-1">
                      Логин админа: <strong>johndoe</strong> / Пароль: <strong>admin</strong>
                    </small>
                    <small>
                      Логин клинета: <strong>janedoe</strong> / Пароль: <strong>client</strong>
                    </small>
                  </v-alert>
                </v-card-text> -->

                <!-- login form -->
                <v-card-text>
                  <v-form
                    ref="loginForm"
                    @submit.prevent="handleFormSubmit"
                  >
                    <v-text-field
                      v-model="email"
                      outlined
                      autocomplete="null"
                      :placeholder="$t('Email')"
                      :error-messages="errorMessages.email"
                      :rules="[validators.required,validators.emailValidator]"
                      hide-details="auto"
                      class="mb-6"
                    ></v-text-field>

                    <v-text-field
                      v-model="password"
                      outlined
                      autocomplete="null"
                      :type="isPasswordVisible ? 'text' : 'password'"
                      :placeholder="$t('Password')"
                      :error-messages="errorMessages.password"
                      :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                      :rules="[validators.required]"
                      hide-details="auto"
                      class="mb-2"
                      @click:append="isPasswordVisible = !isPasswordVisible"
                    ></v-text-field>

                    <!-- <div class="d-flex align-center justify-space-between flex-wrap">
                      <v-checkbox
                        v-model="rememberMe"
                        hide-details
                        label="Запомнить меня"
                        class="mt-0"
                      >
                      </v-checkbox>
                    </div> -->

                    <v-btn
                      block
                      color="primary"
                      type="submit"
                      class="mt-6"
                    >
                      {{ $t('SignIn') }}
                    </v-btn>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <v-snackbar
      v-model="showMessage"
      :timeout="timeout"
      :color="color"
    >
      {{ messageText }}
    </v-snackbar>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { i18n } from '@/plugins/i18n'
import axios from '@axios'
import { useRouter } from '@core/utils'
import { emailValidator, required } from '@core/utils/validation'
import { mdiEyeOffOutline, mdiEyeOutline, mdiFacebook, mdiGithub, mdiGoogle, mdiTwitter } from '@mdi/js'
import themeConfig from '@themeConfig'
import { getCurrentInstance, onMounted, ref } from '@vue/composition-api'

import Vue from 'vue'

export default {
  setup() {
    // Template Ref
    const cabinetAccesRule = 'ACCESS_DASHBOARD_CABINET'
    const loginForm = ref(null)

    const vm = getCurrentInstance().proxy
    const { router } = useRouter()

    const isPasswordVisible = ref(false)

    const email = ref('')
    const password = ref('')
    const rememberMe = ref(false)
    const showMessage = ref(false)
    const messageText = ref('')
    const timeout = ref(5000)
    const color = ref('error')
    const errorMessages = ref([])
    const socialLink = [
      {
        icon: mdiFacebook,
        color: '#4267b2',
        colorInDark: '#4267b2',
      },
      {
        icon: mdiTwitter,
        color: '#1da1f2',
        colorInDark: '#1da1f2',
      },
      {
        icon: mdiGithub,
        color: '#272727',
        colorInDark: '#fff',
      },
      {
        icon: mdiGoogle,
        color: '#db4437',
        colorInDark: '#db4437',
      },
    ]

    onMounted(() => {})

    const handleFormSubmit = () => {
      const isFormValid = loginForm.value.validate()

      if (!isFormValid) return

      /*
        1. Make HTTP request to get accessToken
        2. Store received token in localStorage for future use
        3. Make another HTTP request for getting user information
        4. On successful response of user information redirect to home page

        ? We have use promise chaining to get user data from access token
        ? Promise Chaining: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Using_promises#chaining
      */
      // /auth/login
      // /auth/me
      // `${Vue.prototype.$apiBaseURL}/auth/sign-in`
      axios
        .post(`${Vue.prototype.$apiBaseURL}/auth/sign-in`, { email: email.value, password: password.value })
        .then(response => {
          const { token } = response.data

          // ? Set access token in localStorage so axios interceptor can use it
          // Axios Interceptors: https://github.com/axios/axios#interceptors
          localStorage.setItem('accessToken', token)

          return response
        })
        .then(() => {
          // `${Vue.prototype.$apiBaseURL}/user/details`
          axios.get(`${Vue.prototype.$apiBaseURL}/user/details`).then(response => {
            const user = response.data

            const { privileges } = user

            const userAbility = []

            let findAccess = privileges.find(c => c.name === cabinetAccesRule)

            console.log(findAccess)

            if (!findAccess) {
              showMessage.value = true
              messageText.value = i18n.t('LoginError')

              // console.error('Упс, не получилось войти!')
              console.log('error :>> ', 'Отказано в доступе')
              return
            }

            privileges.forEach(item => {
              userAbility.push({
                action: 'manage',
                subject: item.name,
              })
            })

            userAbility.push({
              action: 'manage',
              subject: 'Public',
            })

            // Set user ability
            // ? https://casl.js.org/v5/en/guide/intro#update-rules
            vm.$ability.update(userAbility)
            console.log(userAbility)

            // Set user's ability in localStorage for Access Control
            localStorage.setItem('userAbility', JSON.stringify(userAbility))

            // We will store `userAbility` in localStorage separate from userData
            // Hence, we are just removing it from user object
            delete user.privileges

            // Set user's data in localStorage for UI/Other purpose
            localStorage.setItem('userData', JSON.stringify(user))

            // On success redirect to home
            router.push('/')
          })
        })
        .catch(error => {
          // TODO: Next Update - Show notification
          showMessage.value = true
          messageText.value = i18n.t('LoginError')

          // console.error('Упс, не получилось войти!')
          console.log('error :>> ', error.response)

          // errorMessages.value = error.response.data.error
        })
    }

    return {
      handleFormSubmit,

      isPasswordVisible,
      email,
      password,
      rememberMe,
      errorMessages,
      showMessage,
      messageText,
      timeout,
      color,
      socialLink,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
      validators: {
        required,
        emailValidator,
      },

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      // Template Refs
      loginForm,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';
</style>
